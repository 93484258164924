import React, {
  FC, memo,
} from 'react';
import { typography } from '@/components/ui/typography';
import { cn } from '@/lib';
import { Button } from '@/components/ui/Button';
import { I18N_CODES } from '@/lib/constants/general';
import { useTranslation } from '@/middleware/i18n';
import {
  ProfessionsListWithCoursesFragment,
} from '@/controllers/profession/generated/professionsListWithCourses.fragment.generated';
import { CourseType } from '@/controllers/graphql/generated';
import { CourseDurationFallback } from '@/components/courseLanding/FormatComparisonSection/FormatComparisonSection.constants';
import { useGrowthBookExperiment } from '@/components/services/GrowthBook/GrowthBook.hooks/useGrowthBookExperiment';
import { GBExperiments } from '@/components/services/GrowthBook/GrowthBook.constants';
import { rawImageLoader } from '@/components/ui/Image/utils/imageLoader';
import styles from '../ProfessionCard/ProfessionCard.module.scss';
import { ProfessionCardHeader } from '../ProfessionCardHeader/ProfessionCardHeader';
import { ProfessionCardHeaderV1 } from '../ProfessionCardHeader/ProfessionCardHeaderV1';

interface Props {
  profession: ProfessionsListWithCoursesFragment;
  singleModeButtonText?: string;
  singleButtonHref: string;
  isButtonLoading: boolean;
  prepaidCourseLink: string;
  handleClick: (type: CourseType) => void;
}

export const ProfessionCardSingle: FC<Props> = memo((props) => {
  const {
    profession,
    singleButtonHref,
    singleModeButtonText,
    isButtonLoading,
    prepaidCourseLink,
    handleClick,
  } = props;

  const {
    name,
    slug,
    description = '',
    backgroundImage,
    minimalCourseDuration = CourseDurationFallback.POSTPAID,
  } = profession;

  const { t } = useTranslation([I18N_CODES.home]);

  const { isVariant1 } = useGrowthBookExperiment(
    GBExperiments.professionCardTags.feature,
  );

  const backgroundImageUrl = backgroundImage?.url && rawImageLoader(
    { src: backgroundImage.url },
  );

  return (
    <div
      className={cn(
        styles.cardWrapper,
        { [styles.letterCorner]: !backgroundImage },
      )}
      style={{ backgroundImage: `url(${backgroundImageUrl})` }}
      data-qa="profession-card"
    >
      {isVariant1
        ? (
          <ProfessionCardHeaderV1
            name={name}
            href={singleButtonHref}
            courseDuration={minimalCourseDuration}
            slug={slug}
          />
        )
        : (
          <ProfessionCardHeader
            name={name}
            href={singleButtonHref}
            courseDuration={minimalCourseDuration}
          />
        )}

      {description && (
        <p className={cn(typography.landingTextMain, 'mb-32')}>
          {description}
        </p>
      )}

      <div className={cn(styles.buttons, styles.oneButton)}>
        <Button
          mode={Button.mode.BrandSecondary}
          size={Button.size.Large}
          text={singleModeButtonText || t(`${I18N_CODES.home}:more_details_button`)}
          hasFullWidth
          isInternalLink
          isLoading={isButtonLoading}
          disabled={isButtonLoading}
          href={singleButtonHref}
          data-qa={prepaidCourseLink
            ? 'flex-course-more-details-button'
            : 'fulltime-course-more-details-button'}
          onClick={() => handleClick(
            prepaidCourseLink
              ? CourseType.Prepaid
              : CourseType.Postpaid,
          )}
        />
      </div>
    </div>
  );
});
