import { useTranslation } from '@/middleware/i18n';
import { useSubDomainContext } from '@/controllers/subDomain/subDomain.hooks/useSubDomainContext';

export const useIsExistingTranslateByDomain = (code: string) => {
  const { i18n } = useTranslation();
  const { subDomain } = useSubDomainContext();

  if (i18n.exists(`${code}|${subDomain}`)) {
    return true;
  }

  return i18n.exists(code);
};
