import React, { memo, useMemo } from 'react';
import { useTranslation } from '@/middleware/i18n';
import { I18N_CODES } from '@/lib/constants/general';
import { useNumberFormatter } from '@/controllers/i18n/i18n.hooks/useNumberFormatter';
import styles from '@/components/courseLanding/EmploymentGuarantee/EmploymentGuarantee.module.scss';
import { cn } from '@/lib';
import { typography } from '@/components/ui/typography';
import { CourseHeroSticker, StickerColorMode, StickerSide } from '@/components/courseLanding/CourseHeroSticker';
import {
  EmploymentBenefitsMergedLandings,
} from '@/components/courseLanding/EmploymentGuarantee/Components/EmploymentBenefits/EmploymentBenefitsMergedLandings';
import { useCourseAgreementProperties } from '@/controllers/courses/courses.hooks/useCourseAgreementProperties';
import { CourseAgreementBaseFragment } from '@/controllers/graphql/generated';
import { useSubDomainRefundWeeks } from '@/controllers/subDomain/subDomain.hooks/useSubDomainRefundWeeks';

interface Props {
  id?: string;
  courseAgreement?: CourseAgreementBaseFragment;
}

export const EmploymentGuaranteeMergedLandings = memo<Props>((props) => {
  const {
    id,
    courseAgreement,
  } = props;

  const { t } = useTranslation([
    I18N_CODES.price,
    I18N_CODES.course,
    I18N_CODES.aboutUs,
  ]);

  const {
    agreementMonths,
    agreementYears,
    agreementPercentage,
  } = useCourseAgreementProperties(courseAgreement ?? undefined);
  const refundWeeks = useSubDomainRefundWeeks();

  const formatter = useNumberFormatter();
  const studentsCount = useMemo(() => (
    formatter.customFormat(Number(t(`${I18N_CODES.common}:employed_students_count`)))
  ), [t, formatter]);

  const companiesCount = useMemo(() => (
    formatter.customFormat(Number(t(`${I18N_CODES.common}:students_get_job_companies_count`)))
  ), [t, formatter]);

  return (
    <div className={styles.sectionContainer} id={id}>
      <div className='grid-container'>
        <div className="grid-x align-center">
          <div className="cell xlarge-10">
            <div className={cn(styles.guaranteeContentWrapper, 'mb-32 small-mb-24')} data-qa="employment-guarantee-block">
              <div className={styles.guarantyTextWrapper}>
                <h2 className={cn(typography.landingH2, styles.title, 'mb-16')}>
                  {t(`${I18N_CODES.course}:employment_guarantee_title_merged`)}
                </h2>

                <p className={cn(typography.landingTextMain, typography.paragraph, styles.subtitle, 'mb-32 small-mb-24')}>
                  {t(`${I18N_CODES.course}:employment_guarantee_description_merged`, {
                    agreementMonths,
                    agreementYears,
                    agreementPercentage,
                    refundWeeks,
                  })}
                </p>
              </div>

              <div className={styles.guaranteeStatisticWrapper}>
                <div className={cn(styles.statisticItem, styles.green)}>
                  {`${studentsCount}+`}

                  <div className={styles.firstItem}>
                    <CourseHeroSticker
                      colorMode={StickerColorMode.Green}
                      title={t(`${I18N_CODES.course}:employed_students_sticker_title`)}
                      width='227px'
                    />
                  </div>
                </div>

                <div className={styles.divider} />

                <div className={cn(styles.statisticItem, styles.purple)}>
                  {`${companiesCount}+`}

                  <div className={styles.secondItem}>
                    <CourseHeroSticker
                      colorMode={StickerColorMode.Purple}
                      title={t(`${I18N_CODES.course}:students_get_job_companies_sticker_title`)}
                      originSide={StickerSide.Right}
                      width='227px'
                    />
                  </div>
                </div>
              </div>
            </div>

            <EmploymentBenefitsMergedLandings />
          </div>
        </div>
      </div>
    </div>
  );
});
