import { CourseAgreementBaseFragment } from '@/controllers/graphql/generated';
import {
  useDefaultDomain,
} from '@/controllers/subDomain/subDomain.hooks/useDefaultDomain';
import { useFeatures } from '@/controllers/features/features.hooks/useFeatures';
import { Features } from '@/controllers/features/features.constants';

export const useCourseAgreementProperties = (
  courseAgreement?: CourseAgreementBaseFragment,
) => {
  const isDefaultSubdomain = useDefaultDomain();
  const features = useFeatures();

  const shouldUseNewFtAgreement = features.isEnabled(
    Features.NewFtAgreement,
  );

  const AGREEMENT_MONTHS_FALLBACK = isDefaultSubdomain
    && !shouldUseNewFtAgreement
    ? 24
    : 36;

  const AGREEMENT_PERCENTAGE_FALLBACK = isDefaultSubdomain
    && !shouldUseNewFtAgreement
    ? 17
    : 12;

  const agreementMonths = courseAgreement?.agreementMonths
    || AGREEMENT_MONTHS_FALLBACK;
  const agreementYears = Math.trunc((agreementMonths / 12) * 100) / 100;

  const agreementPercentage = courseAgreement?.agreementPercentage
    || AGREEMENT_PERCENTAGE_FALLBACK;

  return {
    agreementMonths,
    agreementYears,
    agreementPercentage,
  };
};
