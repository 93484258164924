import getConfig from 'next/config';
import { AppEnvironments, I18N_CODES } from '@/lib/constants/general';
import { useRouterQuery } from '@/hooks/useRouterQuery';
import { useTranslateByDomain } from '@/hooks/useTranslateByDomain';
import { CourseType } from '@/controllers/graphql/generated';
import { useFeatures } from '@/controllers/features/features.hooks/useFeatures';
import { Features } from '@/controllers/features/features.constants';
import { useGrowthBookExperiment } from '@/components/services/GrowthBook/GrowthBook.hooks/useGrowthBookExperiment';
import { GBExperiments } from '@/components/services/GrowthBook/GrowthBook.constants';
import { useSubDomainContext } from '@/controllers/subDomain/subDomain.hooks/useSubDomainContext';
import { SubDomains } from '@/constants/subdomains';
import { useIsExistingTranslateByDomain } from '@/hooks/useIsExistingTranslateByDomain';

export const useSurveyFormId = (courseType: CourseType) => {
  const {
    formId,
    form_id,
    form_id_modifier: formIdModifier,
  } = useRouterQuery<{
    formId?: string;
    form_id?:string;
    form_id_modifier?: string;
  }>();

  const formIdFromQuery = formId || form_id;

  const { publicRuntimeConfig = {} } = getConfig() || {};
  const { APP_ENV } = publicRuntimeConfig;

  const formType = (
    courseType === CourseType.Postpaid
      ? CourseType.Postpaid
      : CourseType.Prepaid
  );

  const envType = (
    APP_ENV === AppEnvironments.Production
      ? AppEnvironments.Production
      : AppEnvironments.Development
  );

  const translateCode = `${I18N_CODES.common}:${envType}.${formType}.survey_id`;

  let modifier = '';

  if (formIdModifier) {
    modifier = `_${formIdModifier}`;
  }

  const translateCodeWithModifier = `${translateCode}${modifier}`;

  const shouldUseTranslateWithModifier = (
    useIsExistingTranslateByDomain(translateCodeWithModifier)
  );

  const preparedTranslateCode = shouldUseTranslateWithModifier
    ? translateCodeWithModifier
    : translateCode;

  const surveyId = useTranslateByDomain(preparedTranslateCode);

  if (formIdFromQuery) {
    return formIdFromQuery;
  }

  return surveyId;
};

export const useShouldRedirectToApplicationSurveyForm = () => {
  const features = useFeatures();
  const { subDomain } = useSubDomainContext();

  const shouldRedirectToApplicationSurveyForm = (
    subDomain === SubDomains.ua
    && features.isEnabled(Features.FTSignUpSurvey)
  );

  const experiment = shouldRedirectToApplicationSurveyForm
    ? GBExperiments.ftSurveySignUp.feature
    : '';

  const { isVariant1: isApplicationSurvey } = useGrowthBookExperiment(
    experiment,
  );

  return isApplicationSurvey;
};
