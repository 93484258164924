import React, { memo } from 'react';
import { Button } from '@/components/ui/Button';
import { NoGroupOppenings } from './NoGroupOppenings';

interface Props {
  shouldShowNoGroup?: boolean;
  text: string;
  href: string;
  className?: string;
}

export const FormatComparisonButton = memo<Props>(({
  shouldShowNoGroup,
  text,
  href,
  className,
}) => {
  if (shouldShowNoGroup) {
    return (
      <NoGroupOppenings
        className={className}
      />
    );
  }

  return (
    <Button
      mode={Button.mode.BrandPrimary}
      size={Button.size.Large}
      data-qa="format-comparison-button"
      text={text}
      href={href}
      className={className}
    />
  );
});
