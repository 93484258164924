import React, {
  FC, memo, useCallback, useMemo,
} from 'react';
import { cn } from '@/lib';
import { typography } from '@/components/ui/typography';
import { Button } from '@/components/ui/Button';
import { useTranslation } from '@/middleware/i18n';
import { I18N_CODES } from '@/lib/constants/general';
import { useShouldRedirectToConsultationSurvey } from '@/components/user-survey/UserSurvey.hooks/useShouldRedirectToConsultationSurvey.hook';
import { ROUTES } from '@/controllers/router/router.contants';
import { ConsultationCourses } from '@/components/courseLanding/CourseLanding.constants';
import {
  PurpleEllipse,
} from '@/components/homepage-landing/ProfessionsListSection/components/ConsultationCardV2/static/PurpleEllipse';
import {
  PinkEllipse,
} from '@/components/homepage-landing/ProfessionsListSection/components/ConsultationCardV2/static/PinkEllipse';
import {
  OrangeEllipse,
} from '@/components/homepage-landing/ProfessionsListSection/components/ConsultationCardV2/static/OrangeEllipse';
import { useCtaContext } from '@/controllers/cta/Cta.context';
import { COURSE_BLOCKS } from '@/components/courseLanding/CoursePage/CoursePageContent.constants';
import styles from './ConsultationCardV2.module.scss';

type Props = {
  isFullWidthCard?: boolean;
  shouldScrollToConsultationForm?: boolean;
};

export const ConsultationCardV2: FC<Props> = memo((props) => {
  const {
    isFullWidthCard = false,
    shouldScrollToConsultationForm,
  } = props;
  const { t } = useTranslation([I18N_CODES.home, I18N_CODES.common]);

  const shouldRedirectToConsultationSurvey = (
    useShouldRedirectToConsultationSurvey()
  );

  const consultationLink = useMemo(() => {
    const source = 'home_page_course_list';

    return shouldRedirectToConsultationSurvey
      ? ROUTES.consultation.signUpSurvey({
        courseSlug: ConsultationCourses.GeneralConsultation,
        source,
      })
      : `${ROUTES.consultation.index}?source=${source}`;
  }, [shouldRedirectToConsultationSurvey]);

  const ctaContext = useCtaContext();

  const handleCtaClick = useCallback(() => {
    ctaContext.callback({
      source: 'professions-list-section-consultation-card',
    });
  }, [ctaContext]);

  return (
    <div
      className={cn(
        styles.cardWrapper,
        { [styles.cardWrapperFullWidth]: isFullWidthCard },
      )}
    >
      <PurpleEllipse className={cn(styles.ellipse, styles.purpleEllipse)} />
      <PinkEllipse className={cn(styles.ellipse, styles.pinkEllipse)} />
      <OrangeEllipse className={cn(styles.ellipse, styles.orangeEllipse)} />

      <div>
        <h3 className={cn(typography.landingH3, styles.title, 'mb-12')}>
          {t(`${I18N_CODES.home}:courses_parttime_courses_list_banner_title`)}
        </h3>

        <p className={cn(typography.landingTextMain, styles.text)}>
          {t(`${I18N_CODES.home}:courses_parttime_courses_list_banner_text`)}
        </p>
      </div>

      <div
        className={cn(
          styles.buttons,
          { [styles.buttonsFullWidth]: isFullWidthCard },
        )}
      >
        <Button
          mode={Button.mode.Secondary}
          size={Button.size.Large}
          text={t(`${I18N_CODES.common}:get_consultation`)}
          isInternalLink={!shouldScrollToConsultationForm}
          href={shouldScrollToConsultationForm
            ? `#${COURSE_BLOCKS.consultationForm}`
            : consultationLink}
          className={styles.consultationButton}
          onClick={handleCtaClick}
        />
      </div>
    </div>
  );
});
