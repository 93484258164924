import React from 'react';
import { BaseIcon } from '@/components/ui/icons/BaseIcon';
import { FCIcon } from '@/components/ui/icons/typedefs';

export const IconMoneyDollarCircleLine: FCIcon = (props) => (
  <BaseIcon
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M8.0026 14.6688C4.3206 14.6688 1.33594 11.6841 1.33594 8.00212C1.33594 4.32012 4.3206 1.33545 8.0026 1.33545C11.6846 1.33545 14.6693 4.32012 14.6693 8.00212C14.6693 11.6841 11.6846 14.6688 8.0026 14.6688ZM8.0026 13.3354C9.41709 13.3354 10.7736 12.7735 11.7738 11.7734C12.774 10.7732 13.3359 9.4166 13.3359 8.00212C13.3359 6.58763 12.774 5.23107 11.7738 4.23088C10.7736 3.23069 9.41709 2.66878 8.0026 2.66878C6.58812 2.66878 5.23156 3.23069 4.23137 4.23088C3.23117 5.23107 2.66927 6.58763 2.66927 8.00212C2.66927 9.4166 3.23117 10.7732 4.23137 11.7734C5.23156 12.7735 6.58812 13.3354 8.0026 13.3354ZM5.66927 9.33545H9.33594C9.42434 9.33545 9.50913 9.30033 9.57164 9.23782C9.63415 9.17531 9.66927 9.09052 9.66927 9.00212C9.66927 8.91371 9.63415 8.82893 9.57164 8.76641C9.50913 8.7039 9.42434 8.66878 9.33594 8.66878H6.66927C6.22724 8.66878 5.80332 8.49319 5.49076 8.18063C5.1782 7.86807 5.0026 7.44414 5.0026 7.00212C5.0026 6.56009 5.1782 6.13617 5.49076 5.8236C5.80332 5.51104 6.22724 5.33545 6.66927 5.33545H7.33594V4.00212H8.66927V5.33545H10.3359V6.66878H6.66927C6.58086 6.66878 6.49608 6.7039 6.43357 6.76641C6.37106 6.82893 6.33594 6.91371 6.33594 7.00212C6.33594 7.09052 6.37106 7.17531 6.43357 7.23782C6.49608 7.30033 6.58086 7.33545 6.66927 7.33545H9.33594C9.77796 7.33545 10.2019 7.51104 10.5144 7.8236C10.827 8.13616 11.0026 8.56009 11.0026 9.00212C11.0026 9.44414 10.827 9.86807 10.5144 10.1806C10.2019 10.4932 9.77796 10.6688 9.33594 10.6688H8.66927V12.0021H7.33594V10.6688H5.66927V9.33545Z"
      fill="currentColor"
    />
  </BaseIcon>
);
