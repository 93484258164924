import {
  CourseType,
  CourseStatus,
} from '@/controllers/graphql/generated';
import {
  useCoursesList,
} from '@/controllers/courses/courses.hooks/useCoursesList';
import {
  LearningTracksShortInfo,
} from '@/components/courseLanding/OtherLearningTracksBlock/OtherLearingTracksBlock.typedefs';

interface UseOtherCoursesList {
  (
    options: {
      exceptSlug?: string;
      types: CourseType[];
    }
  ): LearningTracksShortInfo[];
}
export const useOtherCoursesList: UseOtherCoursesList = (options) => {
  const { exceptSlug, types } = options;

  const [courses] = useCoursesList({
    statuses: CourseStatus.Active,
    types,
  });

  const coursesShortInfo = courses.map((course) => ({
    id: course.id,
    name: course.nameShort,
    slug: course.slug,
  }));

  if (exceptSlug) {
    return coursesShortInfo.filter((course) => course.slug !== exceptSlug);
  }

  return coursesShortInfo;
};
