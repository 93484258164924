import { LearningTracksShortInfo } from '../OtherLearingTracksBlock.typedefs';
import { useGetOtherProfessionsList } from './useGetOtherProfessionList.hook';

interface UseOtherProfessions {
  (
    exceptSlug?: string,
  ): LearningTracksShortInfo[];
}

export const useOtherProfessions: UseOtherProfessions = (exceptSlug) => {
  const [professions] = useGetOtherProfessionsList();

  if (exceptSlug) {
    return professions.filter((profession) => (
      profession.slug !== exceptSlug
    ));
  }

  return professions;
};
