import { useGrowthBookExperiment } from '@/components/services/GrowthBook/GrowthBook.hooks/useGrowthBookExperiment';
import { GBExperiments } from '@/components/services/GrowthBook/GrowthBook.constants';

interface HookOutput {
  shouldRedirectToCourse: boolean;
  isButtonLoading: boolean;
}

export const useShouldRedirectToCourse = (): HookOutput => {
  const cardRedirectToCoursePl = useGrowthBookExperiment(
    GBExperiments.professionCardRedirectToCoursePl.feature,
  );

  const cardRedirectToCourseUa = useGrowthBookExperiment(
    GBExperiments.professionCardRedirectToCourseUa.feature,
  );

  const isButtonLoading = (
    !cardRedirectToCoursePl.ready
    || !cardRedirectToCourseUa.ready
  );

  const shouldRedirectToCourse = (
    cardRedirectToCoursePl.isVariant1
    || cardRedirectToCourseUa.isVariant1
  );

  return {
    shouldRedirectToCourse,
    isButtonLoading,
  };
};
