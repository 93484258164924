import { CourseType } from '@/controllers/graphql/generated';
import { useAuthUser } from '@/controllers/user/user.hooks/useAuthUser';
import { I18N_CODES } from '@/lib/constants/general';
import { ROUTES } from '@/controllers/router/router.contants';
import { useTranslation } from '@/middleware/i18n';
import {
  CourseLandingPageModuleHelper,
} from '@/components/courseLanding/CourseLandingPageModule/CourseLandingPageModule.helpers/CourseLandingPageModuleHelper';
import {
  useShouldRedirectToConsultationSurvey,
} from '@/components/user-survey/UserSurvey.hooks/useShouldRedirectToConsultationSurvey.hook';
import { useShouldRedirectToApplicationSurveyForm } from '@/components/user-survey/UserSurvey.hooks/useSurveyFormId.hook';

interface UseMergedLandingCTA {
  (options: {
    courseType: CourseType;
    courseSlug: string;
    postpaidCourseSlug?: string;
    prepaidCourseSlug?: string;
    form_id_modifier: string;
    source?: string;
  }) : {
    text: string;
    ctaHref: string;
  };
}

export const useMergedLandingCTA: UseMergedLandingCTA = (options) => {
  const {
    courseType,
    courseSlug,
    source,
    postpaidCourseSlug,
    prepaidCourseSlug,
    form_id_modifier,
  } = options;

  const shouldRedirectToConsultationSurvey = (
    useShouldRedirectToConsultationSurvey()
  );

  const shouldRedirectToApplicationSurvey = (
    useShouldRedirectToApplicationSurveyForm()
  );

  const { t } = useTranslation([I18N_CODES.common, I18N_CODES.cta]);

  const [authUser] = useAuthUser({ ssr: false });

  const mergedSurveyOptions = {
    courseSlug,
    source,
    form_id_modifier,
    postpaidCourseSlug,
    prepaidCourseSlug,
  };

  if (!authUser) {
    return {
      text: t(`${I18N_CODES.cta}:profession_cover_cta_button`),
      ctaHref: courseType === CourseType.Postpaid
        ? ROUTES.application.signUpSurvey({
          ...mergedSurveyOptions,
        })
        : ROUTES.consultation.signUpSurvey({
          ...mergedSurveyOptions,
        }),
    };
  }

  if (courseType === CourseType.Postpaid) {
    return {
      text: t(`${I18N_CODES.common}:study_for_free`),
      ctaHref: CourseLandingPageModuleHelper.getApplicationLink({
        shouldRedirectToApplicationSurvey,
        courseSlug,
      }),
    };
  }

  return {
    text: t(`${I18N_CODES.common}:get_consultation`),
    ctaHref: CourseLandingPageModuleHelper.getConsultationLink({
      shouldRedirectToConsultationSurvey,
      courseSlug,
    }),
  };
};
