import React, { memo } from 'react';
import { I18N_CODES } from '@/lib/constants/general';
import { useTranslation } from '@/middleware/i18n';
import { IconInfo } from '@/components/ui/icons/IconInfo';
import { ToolTip, ToolTipWidthMode } from '@/components/ui/ToolTip';
import { Button } from '@/components/ui/Button';
import { cn } from '@/lib';
import styles from '../FormatComparisonSection.module.scss';

interface Props {
  className?: string;
}

export const NoGroupOppenings = memo<Props>((props) => {
  const { className } = props;

  const { t } = useTranslation([I18N_CODES.course]);

  return (
    <ToolTip
      text={t(`${I18N_CODES.course}:format_comparison_no_group_description`)}
      widthMode={ToolTipWidthMode.Full}
      isManualVerticalPositioning
      className={className}
      messageClassName={styles.tooltipMessage}
    >
      <Button
        mode={Button.mode.Secondary}
        size={Button.size.Large}
        className={cn(styles.noGroupButton, className)}
        text={t(`${I18N_CODES.course}:format_comparison_no_group`)}
        RightIcon={() => (
          <IconInfo className={cn('ml-8', styles.iconInfo)} />
        )}
      />
    </ToolTip>
  );
});
