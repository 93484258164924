import React, { FC, memo } from 'react';
import { Link, useTranslation } from '@/middleware/i18n';
import { typography } from '@/components/ui/typography';
import { I18N_CODES } from '@/lib/constants/general';
import { cn } from '@/lib';
import styles from '../ProfessionCard/ProfessionCard.module.scss';

interface Props {
  href: string;
  name: string;
  courseDuration: number | null;
}

export const ProfessionCardHeader: FC<Props> = memo((props) => {
  const {
    href,
    name,
    courseDuration,
  } = props;

  const { t } = useTranslation([I18N_CODES.home]);

  return (
    <>
      <Link href={href}>
        <a className={cn(typography.landingH3, styles.title, 'mb-12')}>
          <h3>{name}</h3>
        </a>
      </Link>

      <p className={cn(typography.landingTextMain, styles.subtitle, 'mb-24')}>
        <span>
          {t(`${I18N_CODES.home}:home_course_duration`, {
            duration: courseDuration,
            count: Number(courseDuration),
          })}
        </span>

        <span>•</span>

        <span>{t(`${I18N_CODES.home}:newbie_friendly`)}</span>
      </p>
    </>
  );
});
